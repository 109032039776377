import { createStitches } from '@stitches/react';

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config
} = createStitches({
  theme: {
    colors: {
      brand: '#FF479D',
      primary: '#E8F7EE',
      secondary: '#188FA7',
      tertiary: '##90b3f5',
      black: '#171717',
      white: '#f5f5f5',
      grayDark: '#67697C',
      grayLight: '#e8e8e8',
      pinkWhite: '#FFF7FA',
      darkPink: '#140007'
    },
    space: {
      base: '1rem',
      xs: '0.75rem',
      sm: '1rem',
      md: '1.5rem',
      lg: '2rem',
      xl: '3rem',
      xxl: '4rem',
      xxxl: '5rem',
      jumbo: '7rem'
    }
  },
  media: {
    mobile: '(min-width: 480px)',
    tablet: '(min-width: 768px)',
    tabletLg: '(min-width: 968px)',
    desktop: '(min-width: 1480px)'
  }
});
