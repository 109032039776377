import { globalCss } from 'stitches.config';

export const globalStyles = globalCss({
  '*': { boxSizing: 'border-box' },
  '@font-face': {
    fontFamily: 'Poppins',
    src: 'local("Poppins")'
  },
  'html, body, #root': {
    fontSize: '1em',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexFlow: 'column nowrap',
    fontFamily: 'sans-serif',
    color: '$colors$black',
    backgroundColor: '#FFFFFF',
    minHeight: '100vh'
  },
  'h1,h2,h3,h4,h5,h6': {
    fontFamily: 'Poppins'
  }
});
